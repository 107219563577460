import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LCAAnalysisType } from 'src/app/data-transfer/entities/environmental-effect-authorization-dto';

export enum BuyType {
  EnvironmentallEffect = 0,
  Country = 1,
  AllCountries = 2
}

@Component({
  selector: 'app-buying-from-shop',
  templateUrl: './buying-from-shop.component.html',
  styleUrls: ['./buying-from-shop.component.scss']
})

export class BuyingFromShopComponent {
  @Input() buyType : BuyType = BuyType.EnvironmentallEffect;
  @Input() contentValue : string = '';
  @Input() analysisType : LCAAnalysisType = LCAAnalysisType.CradleToGrave;

  showBuyText : boolean = false;

  constructor(private translateService: TranslateService) {

  }

  protected getTitleString () : string {
    switch(this.buyType) {
      case BuyType.EnvironmentallEffect:
        return this.translateService.instant('analysis.lifecycleAnalysis.warnings.impactNotAuthorized', {name: this.contentValue})
      case BuyType.Country:
        return this.translateService.instant('analysis.lifecycleAnalysis.warnings.countryNotAuthorized', {name: this.contentValue})
      case BuyType.AllCountries:
        return this.translateService.instant('analysis.lifecycleAnalysis.warnings.noCountriesAvailable')
    }
  }
}
