import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { MultiMaterialCompositeDto } from '../entities/material-entities/multi-material-composite-dto';
import { MaterialApiService } from '../services/material-api-service';
import { inject } from '@angular/core';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { AuthService } from 'src/app/services/auth-service';
import { ChangeText, OriginalText } from 'src/app/model/path-building-blocks';

export const compositeMaterialResolver: ResolveFn<MultiMaterialCompositeDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getIdFromRoute(route);
  return id != null ? inject(MaterialApiService).getCompositeMaterial(id) : EMPTY;
};

export const compositeMaterialHistoryResolver: ResolveFn<MultiMaterialCompositeDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ? inject(MaterialApiService).getCompositeMaterial(id, versionId) : EMPTY;
};

export const lastViewedMaterialsResolver: ResolveFn<MultiMaterialCompositeDto[]> = () => {
  if (!inject(AuthService).isUserValidator()) {
    return inject(MaterialApiService).getLastViewedMaterials(8);
  } else {
    return of([]);
  }
};

export const compositeMaterialPermissionsResolver: ResolveFn<PermissionTypeDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getIdFromRoute(route);
  return id != null ? inject(MaterialApiService).getMaterialPermissions(id) : EMPTY;
};

export const changedSubaterialsResolver: ResolveFn<number[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getIdFromRoute(route);
  return id != null ? inject(MaterialApiService).getSubmaterialChanges(id) : EMPTY;
};

export const compositeMaterialPreviewResolver: ResolveFn<MultiMaterialCompositeDto> = (route: ActivatedRouteSnapshot) => {
  const candidateId = PathResolveService.getCandidateIdFromRoute(route);
  if (candidateId == null) { return EMPTY; }

  const url = route.url.map(segment => segment.toString());
  if (url.findIndex(x => x === ChangeText) > -1) {
    if (url.findIndex(y => y === OriginalText) > -1) {
      return resolveChange(candidateId, true);
    }
    return resolveChange(candidateId, false);
  } else {
    return resolveImport(candidateId);
  }
};

const resolveImport = (candidateId: number) => {
  return inject(MaterialApiService).getMaterialPreview(candidateId);
};

const resolveChange = (candidateId: number, isOriginal = false) => {
  return isOriginal ? inject(MaterialApiService).getCompositeMaterial(candidateId) :
    inject(MaterialApiService).getMaterialPreview(candidateId);
}
