export class ReportInfoDto {
    id!: number;
    name?: string;
    reportProfileId?: number;
    countries!: string[];
    createdByUserId?: number;
    creationTimestamp?: string;
    lastModifiedByUserId?: number;
    lastModificationTimestamp?: string;
    lastModification?: number;
}

export class PackagingSystemReportInfoDto extends ReportInfoDto {
  packagingSystemId!: number;
  packagingSystemVersion!: number;
}
export class PackagingUnitReportInfoDto extends ReportInfoDto {
    packagingUnitId!: number;
    packagingUnitVersion!: number;
}

export class ComponentReportInfoDto extends ReportInfoDto {
    componentId!: number;
    componentVersion!: number;
}
