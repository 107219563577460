<mat-menu #menu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [matMenuTriggerFor]="templatesMenu" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.templates' | translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="reportsMenu" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.reports' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #templatesMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [matMenuTriggerFor]="packagingPartTemplateMenuCreate" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.buttons.createTemplate' | translate}}</span>
  </button>
  <button mat-menu-item (click)="viewReportTemplates()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.buttons.viewTemplates' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #reportsMenu="matMenu" [overlapTrigger]="false">
  <!-- <button mat-menu-item disabled type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.buttons.createReport' | translate}}</span>
  </button> -->
  <button mat-menu-item (click)="viewReports()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.buttons.viewReports' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #packagingPartTemplateMenuCreate="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="createPackagingSystemReportTemplate()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.packagingSystemTemplate' | translate }}</span>
  </button>
  <button mat-menu-item (click)="createPackagingReportTemplate()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.packagingUnitTemplate' | translate }}</span>
  </button>
  <button mat-menu-item (click)="createComponentReportTemplate()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.componentTemplate' | translate }}</span>
  </button>
  <!-- <button mat-menu-item (click)="createMaterialReportTemplate()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'report.materialTemplate' | translate }}</span>
  </button> -->
</mat-menu>
