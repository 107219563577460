import { TranslateService } from '@ngx-translate/core';
import { PathResolveService } from '../../../services/path-resolve.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnalysisType, ParentNavigationService } from './parent-navigation-service';
import { MatDialog } from '@angular/material/dialog';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { Observable } from 'rxjs';
import { EvaluationGenerationDto } from 'src/app/data-transfer/entities/evaluation-entities/evaluation-generation-dto';
import { AnalysisNotPossibleDialogComponent } from 'src/app/components/dialogs/analysis-not-possible-dialog/analysis-not-possible-dialog.component';
import { RecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/recyclability-result-dto';
import { LifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/life-cycle-result-dto';
import { ExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';
import { ProblemInformationDto } from 'src/app/data-transfer/entities/evaluation-entities/problem-information-dto';
@Injectable({ providedIn: 'root' })
export class PackagingSystemNavigationService extends ParentNavigationService {

  constructor(
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    private router: Router,
    private analysisApiService: AnalysisApiService,
    private spinner: NgxSpinnerService
  ) {
    super(dialog, translateService);
  }

  navigateToPackagingSystem(packagingSystemId: number, versionId?: number) {
    const path = versionId == null ?
      PathResolveService.getUpdatePackagingSystemPath(true, packagingSystemId) :
      PathResolveService.getPackagingSystemHistoryPath(true, packagingSystemId, versionId);
    this.router.navigate([path]);
  }

  navigateToPackagingSystemPreview(candidateId: number, isChange = false, seePackagingSystemBeforeChange = false) {
    this.router.navigate([PathResolveService.getPackagingSystemPreviewPath(true, candidateId, isChange, seePackagingSystemBeforeChange)]);
  }

  navigateToAnalysisWithCostConfirmation(
    analysisType: AnalysisType, packagingSystemId: number, versionId: number,
    problemInformation: ProblemInformationDto[]
  ) {
    const isAnalysisPossible = problemInformation.length == 0;

    if (!isAnalysisPossible) {
      this.stopWhenAnalysisNotPossible(problemInformation);
    } else {
      this.navigateToAnalysis(analysisType, packagingSystemId, versionId);
    }
  }

  private navigateToAnalysis(
    analysisType: AnalysisType, packagingSystemId: number, versionId: number
  ) {
    let observableAnalysis: Observable<LifeCycleResultDto[] | RecyclabilityResultDto[] | ExpenseResultDto[]> | null = null;
    const dto: EvaluationGenerationDto = { id: packagingSystemId, version: versionId };
    if (analysisType === AnalysisType.Recyclability) {
      observableAnalysis = this.analysisApiService.getRecyclabilityEvaluationResult(dto, PackagingPart.System);
    } else if (analysisType === AnalysisType.Lifecycle) {
      observableAnalysis = this.analysisApiService.getLifeCycleEvaluationResult(dto, PackagingPart.System);
    } else if (analysisType === AnalysisType.CradleToGateLifecycle) {
      observableAnalysis = this.analysisApiService.getCradleToGateEvaluationResult(dto, PackagingPart.System);
    } else if (analysisType === AnalysisType.PlasticTax) {
      this.router.navigate([PathResolveService.getTempPlasticTaxSystemPath(true, packagingSystemId, versionId)]);
    } else if (analysisType === AnalysisType.LicenseFee) {
      this.router.navigate([PathResolveService.getTempLicenseFeeSystemPath(true, packagingSystemId, versionId)]);
    }
    if (!observableAnalysis) { return; }

    this.evaluationSubscription = observableAnalysis.subscribe(analyses => {
      this.navigateToHistoryAnalysis(analysisType, packagingSystemId, versionId, analyses[0].id ?? '');
    });
  }

  private stopWhenAnalysisNotPossible(problemInformation: ProblemInformationDto[]): void {
    this.spinner.hide();
    const alertDialogConfig = getDialogConfig({ packagingPart: PackagingPart.System, problemInformation: problemInformation}, '600px');
    this.dialog.open(AnalysisNotPossibleDialogComponent, alertDialogConfig);
  }

  navigateToHistoryAnalysis(
    analysisType: AnalysisType, packagingId: number,
    packagingVersionId: number, analysisVersionId: string
  ) {
    let path = '';
    switch (analysisType) {
      case AnalysisType.Recyclability: {
        path = PathResolveService.getRecyclabilityAnalysisHistoryPathSystem(true, packagingId, packagingVersionId, analysisVersionId);
        break;
      }
      case AnalysisType.CradleToGateLifecycle: {
        path = PathResolveService.getCradleToGateLifecycleAnalysisHistoryPathSystem(true, packagingId, packagingVersionId, analysisVersionId);
        break;
      }
      case AnalysisType.Lifecycle: {
        path = PathResolveService.getLifecycleAnalysisHistoryPathSystem(true, packagingId, packagingVersionId, analysisVersionId);
        break;
      }
    }
    this.router.navigate([path]);
  }

  navigateToComparison(listOfIdsToCompare: number[]) {
      this.router.navigate([PathResolveService.getCompareRecyclabilityAnalysisPathSystem(true, listOfIdsToCompare)]);
  }
}
