import { ValidationState } from './../../various-components/validator-view/validator-view.component';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ValidatorApiService } from 'src/app/data-transfer/services/validator-api-service';
import { ColorThemeService, COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';
import { AuthService } from 'src/app/services/auth-service';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { RecyclabilityResult } from 'src/app/model/evaluations/recyclability-result';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { SimplifiedLcaDataSource } from '../analysis-life-cycle/analysis-life-cycle.component';

@Component({
  selector: 'app-analysis-parent',
  templateUrl: './analysis-parent.component.html',
  styleUrls: ['./analysis-parent.component.scss']
})
export class AnalysisParentComponent implements OnDestroy {

  analysisId = '';
  packagingPartId!: number;
  currentDirectoryId!: number;
  packagingPartVersion!: number;
  brandName = '';
  productName = '';
  articleNumber = '';
  isCurrentVersion = false;
  hasRecyclabilityResult = false;
  hasLifeCycleResult = false;
  creationTimestamp!: Date;
  isUserValidator = false;
  isDarkTheme = false;
  validationStates = ValidationState;
  protected callerLevelId!: number;
  packagingPartEnum = PackagingPart;
  packagingUnitTypeId!: number;

  protected routeDataSubscription?: Subscription;
  protected routeParamsSubscription?: Subscription;
  protected routeUrlSubscription?: Subscription;
  private validationSubscription?: Subscription;
  private themeSubscription?: Subscription;

  constructor(
    protected validatorApiService: ValidatorApiService,
    protected colorThemeService: ColorThemeService,
    protected authService: AuthService) {
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
    this.isUserValidator = this.authService.isUserValidator();
  }

  protected setPackagingPartData(packagingPart: PackagingUnitDto | PackagingSystemDto) {
    this.packagingPartId = packagingPart.id;
    this.currentDirectoryId = packagingPart.directoryId;
    this.packagingPartVersion = packagingPart.version;
    this.brandName = packagingPart.brandName;
    this.productName = packagingPart.productName;
    this.articleNumber = packagingPart.articleNumber;
    this.isCurrentVersion = packagingPart.isCurrentVersion;
    this.hasRecyclabilityResult = packagingPart.hasRecyclabilityResult;
    this.hasLifeCycleResult = packagingPart.hasLifeCycleResult;
    this.creationTimestamp = packagingPart.creationTimestamp;
  }

  getDateTimeString(date?: string) {
    if (date == null) { return; }
    const dateDate = new Date(date);
    return `${dateDate.toLocaleDateString()}: ${dateDate.toLocaleTimeString()}`;
  }

  validateAnalysis(valid: boolean, evaluatedCountry: RecyclabilityResult | SimplifiedLcaDataSource) {
    const analysisId = evaluatedCountry.analysisId?.concat('_' + evaluatedCountry.evaluatedCountryCode);
    if (analysisId == null) { return; }
    const validationState = valid ? ValidationState.Positive : valid === false ? ValidationState.Negative : ValidationState.Unknown;
    this.validationSubscription = this.validatorApiService.validateAnalysis(analysisId, validationState).subscribe();
    evaluatedCountry.validationState = validationState;
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.routeParamsSubscription?.unsubscribe();
    this.routeUrlSubscription?.unsubscribe();
    this.validationSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }
}
