import { Observable } from 'rxjs';
import { AnalysisNotPossibleDialogComponent } from '../../../components/dialogs/analysis-not-possible-dialog/analysis-not-possible-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PathResolveService } from '../../../services/path-resolve.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnalysisType, ParentNavigationService } from './parent-navigation-service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { EvaluationGenerationDto } from 'src/app/data-transfer/entities/evaluation-entities/evaluation-generation-dto';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { RecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/recyclability-result-dto';
import { LifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/life-cycle-result-dto';
import { ProblemInformationDto } from 'src/app/data-transfer/entities/evaluation-entities/problem-information-dto';
import { ExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';

@Injectable({ providedIn: 'root' })
export class PackagingUnitNavigationService extends ParentNavigationService {

  constructor(
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    private router: Router,
    private analysisApiService: AnalysisApiService,
    private spinner: NgxSpinnerService
  ) {
    super(dialog, translateService);
  }

  navigateToPackagingUnit(packagingUnitId: number, packagingUnitVersion?: number) {
    const path = packagingUnitVersion == null ?
      PathResolveService.getUpdatePackagingUnitPath(true, packagingUnitId) :
      PathResolveService.getPackagingUnitHistoryPath(true, packagingUnitId, packagingUnitVersion);
    this.router.navigate([path]);
  }

  navigateToAnalysisWithCostConfirmation(
    analysisType: AnalysisType, packagingUnitId: number, versionId: number,
    problemInformation: ProblemInformationDto[]
  ) {
    const isAnalysisPossible = problemInformation.length == 0;

    if (!isAnalysisPossible) {
      this.stopWhenAnalysisNotPossible(problemInformation);
    } else {
      this.navigateToAnalysis(analysisType, packagingUnitId, versionId);
    }
  }

  private navigateToAnalysis(
    analysisType: AnalysisType, packagingUnitId: number, versionId: number
  ) {
    let observableAnalysis: Observable<LifeCycleResultDto[] | RecyclabilityResultDto[] | ExpenseResultDto[]> | null = null;
    const dto: EvaluationGenerationDto = { id: packagingUnitId, version: versionId };
    if (analysisType === AnalysisType.Recyclability) {
      observableAnalysis = this.analysisApiService.getRecyclabilityEvaluationResult(dto, PackagingPart.Unit);
    } else if (analysisType === AnalysisType.Lifecycle) {
      observableAnalysis = this.analysisApiService.getLifeCycleEvaluationResult(dto, PackagingPart.Unit);
    } else if (analysisType === AnalysisType.CradleToGateLifecycle) {
      observableAnalysis = this.analysisApiService.getCradleToGateEvaluationResult(dto, PackagingPart.Unit);
    } else if (analysisType === AnalysisType.PlasticTax) {
      observableAnalysis = this.analysisApiService.getPlasticTaxCalculationResult(dto, PackagingPart.Unit);
    } else if (analysisType === AnalysisType.LicenseFee) {
      observableAnalysis = this.analysisApiService.getLicenseFeeCalculationResult(dto, PackagingPart.Unit);
    }
    if (!observableAnalysis) { return; }
      this.evaluationSubscription = observableAnalysis.subscribe(analyses => {
        this.navigateToHistoryAnalysis(analysisType, packagingUnitId, versionId, analyses[0].id ?? '');
      });
  }

  private stopWhenAnalysisNotPossible(problemInformation: ProblemInformationDto[]): void {
    this.spinner.hide();
    const alertDialogConfig = getDialogConfig({ packagingPart: PackagingPart.Unit, problemInformation: problemInformation }, '600px');
    this.dialog.open(AnalysisNotPossibleDialogComponent, alertDialogConfig);
  }

  navigateToHistoryAnalysis(
    analysisType: AnalysisType, packagingId: number, packagingVersionId: number, analysisVersionId: string
  ) {
    switch (analysisType) {
      case AnalysisType.Recyclability: {
        this.router.navigate([PathResolveService.getRecyclabilityAnalysisHistoryPathUnit(
          true, packagingId, packagingVersionId, analysisVersionId)]);
        break;
      }
      case AnalysisType.CradleToGateLifecycle: {
        this.router.navigate([PathResolveService.getCradleToGateLifecycleAnalysisHistoryPathUnit(
          true, packagingId, packagingVersionId, analysisVersionId)]);
        break;
      }
      case AnalysisType.Lifecycle: {
        this.router.navigate([PathResolveService.getLifecycleAnalysisHistoryPathUnit(
          true, packagingId, packagingVersionId, analysisVersionId)]);
        break;
      }
      case AnalysisType.PlasticTax: {
        this.router.navigate([PathResolveService.getPlasticTaxHistoryPathUnit(
          true, packagingId, packagingVersionId, analysisVersionId)]);
        break;
      }
      case AnalysisType.LicenseFee: {
        this.router.navigate([PathResolveService.getLicenseFeeHistoryPathUnit(
          true, packagingId, packagingVersionId, analysisVersionId)]);
        break;
      }
    }
  }

  navigateToComparison(listOfIdsToCompare: number[]) {
    this.router.navigate([PathResolveService.getCompareRecyclabilityAnalysisPathUnit(true, listOfIdsToCompare)]);
  }

  navigateToPackagingUnitPreview(candidateId: number, isChange = false, seePackagingUnitBeforeChange = false) {
    this.router.navigate([PathResolveService.getPackagingUnitPreviewPath(true, candidateId, isChange, seePackagingUnitBeforeChange)]);
  }
}
