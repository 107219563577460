import { setValues } from "../packaging-unit-entities/packaging-unit-export-profile-dto";
import { LifeCycleReportDto } from '../packaging-unit-entities/packaging-unit-report-dto';

export class PackagingSystemReportDto {
  id?: number;
  profileName?: string;
  owningOrganizationId?: number;
  creationTimestamp?: string;
  isDeleted = false;
  isDefaultProfile = false;
  documents = false;
  recyclabilityResult :boolean = true;
  showRecyclabilityMethodology = false;
  recyclabilityChart = false;
  countryOverview = false;
  packagingSystemHierarchy = false;

  subPackagingSystems = false;
  completeHierarchy = false;
  packagingUnit = false;

  masterData: PackagingSystemFieldsDto;
  packagingUnitMainBody = false;
  packagingUnitClosure = false;
  packagingUnitDecoration = false;
  packagingUnitInlay = false;
  packagingUnitPackagingAid = false;
  subMasterData: PackagingSystemFieldsDto;
  subPackagingUnit =false;
  subPackagingUnitMainBody = false;
  subPackagingUnitClosure = false;
  subPackagingUnitDecoration = false;
  subPackagingUnitInlay = false;
  subPackagingUnitPackagingAid = false;
  lifeCycleResult: LifeCycleReportDto;

  constructor(defaultBooleanValue?: boolean) {
    this.masterData = new PackagingSystemFieldsDto(defaultBooleanValue);
    this.subMasterData = new PackagingSystemFieldsDto(defaultBooleanValue);
    this.lifeCycleResult = new LifeCycleReportDto(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    this.documents = defaultBooleanValue;
    this.recyclabilityResult = true;
    this.showRecyclabilityMethodology = defaultBooleanValue;
    this.recyclabilityChart = defaultBooleanValue;
    this.countryOverview = defaultBooleanValue;
    this.packagingSystemHierarchy = defaultBooleanValue;
    this.subPackagingSystems = defaultBooleanValue;
    this.completeHierarchy = defaultBooleanValue;
    this.packagingUnit = defaultBooleanValue;
    this.packagingUnitMainBody = defaultBooleanValue;
    this.packagingUnitClosure = defaultBooleanValue;
    this.packagingUnitDecoration = defaultBooleanValue;
    this.packagingUnitInlay = defaultBooleanValue;
    this.packagingUnitPackagingAid = defaultBooleanValue;
    this.subPackagingUnit =defaultBooleanValue;
    this.subPackagingUnitMainBody = defaultBooleanValue;
    this.subPackagingUnitClosure = defaultBooleanValue;
    this.subPackagingUnitDecoration = defaultBooleanValue;
    this.subPackagingUnitInlay = defaultBooleanValue;
    this.subPackagingUnitPackagingAid = defaultBooleanValue;
  }

  static updateRecyclingResultsField(dto: PackagingSystemReportDto): PackagingSystemReportDto {
    dto.recyclabilityResult = (dto.recyclabilityChart || dto.showRecyclabilityMethodology);
    return dto;
  }
}

export class PackagingSystemFieldsDto {
  packagingSystemId = false;
  version = false;
  comment = false;
  brandName = false;
  productName = false;
  articleNumber = false;
  gtin = false;
  dimensions = false;
  distributionCountries = false;
  assemblyCountry = false;
  isTransportPackaging = false;
  images = false;
  documents = false;

  constructor(defaultBooleanValue?: boolean) {
    setValues(this, defaultBooleanValue);
  }
}
