<mat-card id="outer-card">
  <mat-card-header>
    <mat-card-title>{{ 'report.templates' | translate }}</mat-card-title>
  </mat-card-header>
  <div class="local-scrolling-wrapper">
    <mat-tab-group>
      <mat-tab label="{{ 'packagingSystem.packagingSystems' | translate }}">
        <app-report-templates-overview [reportTemplates]="packagingSystemReportTemplates"
          [displayedColumns]="displayedColumns" [callerLevel]="callerPart.System">
        </app-report-templates-overview>
      </mat-tab>
      <mat-tab label="{{ 'packagingUnit.packagingUnits' | translate }}">
        <app-report-templates-overview [reportTemplates]="packagingUnitReportTemplates"
          [displayedColumns]="displayedColumns" [callerLevel]="callerPart.Unit">
        </app-report-templates-overview>
      </mat-tab>
      <mat-tab label="{{ 'component.packagingComponents' | translate }}">
        <app-report-templates-overview [reportTemplates]="componentReportTemplates"
          [displayedColumns]="displayedColumns" [callerLevel]="callerPart.Component">
        </app-report-templates-overview>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>
