import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ComponentReportDto } from '../entities/component-entities/component-report-dto';
import { ReportApiService } from '../services/report-api-service';
import { PackagingUnitReportDto } from '../entities/packaging-unit-entities/packaging-unit-report-dto';
import { PackagingSystemReportDto } from '../entities/packaging-system-entities/packaging-system-report-dto';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { EMPTY } from 'rxjs';

export const reportsPackagingSystemResolver: ResolveFn<PackagingSystemReportDto[]> = () => {
  return inject(ReportApiService).getAllPackagingSystemReports();
};

export const reportsPackagingUnitResolver: ResolveFn<PackagingUnitReportDto[]> = () => {
  return inject(ReportApiService).getAllPackagingUnitReports();
};

export const reportsComponentResolver: ResolveFn<ComponentReportDto[]> = () => {
  return inject(ReportApiService).getAllComponentReports();
};

export const reportTemplatesPackagingSystemResolver: ResolveFn<PackagingSystemReportDto[]> = () => {
  return inject(ReportApiService).getAllPackagingSystemReportTemplates();
};

export const reportTemplatesPackagingUnitResolver: ResolveFn<PackagingUnitReportDto[]> = () => {
  return inject(ReportApiService).getAllPackagingUnitReportTemplates();
};

export const reportTemplatesComponentResolver: ResolveFn<ComponentReportDto[]> = () => {
  return inject(ReportApiService).getAllComponentReportTemplates();
};

export const reportTemplatePackagingSystemResolver: ResolveFn<PackagingSystemReportDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getIdFromRoute(route);
  return id != null ? inject(ReportApiService).getPackagingSystemReportTemplateById(id) : EMPTY;
};

export const reportTemplatePackagingUnitResolver: ResolveFn<PackagingUnitReportDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getIdFromRoute(route);
  return id != null ? inject(ReportApiService).getPackagingUnitReportTemplateById(id) : EMPTY;
};

export const reportTemplateComponentResolver: ResolveFn<ComponentReportDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getIdFromRoute(route);
  return id != null ? inject(ReportApiService).getComponentReportTemplateById(id) : EMPTY;
};
