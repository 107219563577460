import { AnalysisParentComponent } from './../analysis-parent/analysis-parent.component';
import { ColorThemeService } from './../../../navigation/services/color-theme-service';
import { ValidatorApiService } from '../../../data-transfer/services/validator-api-service';
import { AuthService } from './../../../services/auth-service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingUnitText } from 'src/app/model/path-building-blocks';
import { RecyclabilityResult } from 'src/app/model/evaluations/recyclability-result';
import { PackagingUnitRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { RecyclabilityResultPackagingUnit } from 'src/app/model/evaluations/recyclability-result-packaging-unit';
import { PackagingSystemRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';
import { RecyclabilityResultPackagingSystem } from 'src/app/model/evaluations/recyclability-result-packaging-system';
import { RecyclingStreamTranslationsDto } from 'src/app/data-transfer/entities/evaluation-entities/recycling-stream-dto';
import { RecyclabilityService } from 'src/app/util/analyses-util/recyclability/recyclability-service';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { LicenseService } from 'src/app/services/licensing-service'
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { BuyType } from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'

@Component({
  selector: 'app-analysis-recyclability',
  templateUrl: './analysis-recyclability.component.html',
  styleUrls: ['./analysis-recyclability.component.scss']
})
export class AnalysisRecyclabilityComponent extends AnalysisParentComponent implements OnInit {

  @ViewChild('countryTabs') countryTabs!: MatTabGroup;

  dataSource: (RecyclabilityResultPackagingUnit | RecyclabilityResultPackagingSystem)[] = [];
  dataSourceMap: (RecyclabilityResultPackagingUnit | RecyclabilityResultPackagingSystem)[] = [];
  analysisVersions: (PackagingUnitRecyclabilityResultDto | PackagingSystemRecyclabilityResultDto)[][] = [];
  selectedAnalysisVersion: (PackagingUnitRecyclabilityResultDto | PackagingSystemRecyclabilityResultDto)[] = [];

  recyclingStreams: RecyclingStreamTranslationsDto[] = [];
  packagingSystem: PackagingSystemDto | null = null;
  packagingUnit: PackagingUnitDto | null = null;

  allowedCountries: CountryDto[] = [];
  authorizedCountries : Map<string, CountryDto[]> = new Map();

  buyType = BuyType;

  constructor(
    protected validatorApiService: ValidatorApiService,
    protected colorThemeService: ColorThemeService,
    protected authService: AuthService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private recService: RecyclabilityService,
    protected licenseService : LicenseService,
  ) {
    super(validatorApiService, colorThemeService, authService);
  }

  ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      this.analysisId = params.analysisVersionId ? params.analysisVersionId : '';
    });
    this.routeUrlSubscription = this.route.url.subscribe(segments => {
      this.callerLevelId = segments.map(x => x.path).includes(PackagingUnitText) ? PackagingPart.Unit : PackagingPart.System;
    })
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const packagingPart = data.packagingUnit ? data.packagingUnit : data.packagingSystem;
      if (this.callerLevelId === PackagingPart.System) {
        this.packagingSystem = packagingPart;
      } else if (this.callerLevelId === PackagingPart.Unit) {
        this.packagingUnit = packagingPart;
        this.packagingUnitTypeId = packagingPart.packagingTypeId;
      }
      super.setPackagingPartData(packagingPart);
      this.allowedCountries = this.licenseService.allowedCountries;
      this.analysisVersions = data.recyclabilityEvaluationHistoryResults;
      this.selectedAnalysisVersion = this.getSelectedAnalysisVersion();
      this.setAuthorizations(this.selectedAnalysisVersion[0].evaluationReferenceTimestamp);
      this.setDataSourceForVersion(this.selectedAnalysisVersion);
      this.recyclingStreams = data.recyclingStreams;
    });
  }

  getDisclaimerAsHtml(disclaimer: any) {
    disclaimer = this.translateService.instant(disclaimer);
    let innerHtml = '';
    for (const paragraph of disclaimer.paragraphs) {
      if (typeof paragraph === 'string') {
        innerHtml += `<p>${paragraph}</p>`;
      }
      if (paragraph.type === 'list') {
        innerHtml += '<p>';
        innerHtml += this.getList(paragraph);
        innerHtml += '</p>';
      }
    }
    return innerHtml;
  }

  private getList(list: any) {
    let listHtml = '';
    if (list.text) {
      listHtml += `<li>${list.text}</li>`;
    }
    listHtml += '<ul>';
    for (const item of list.items) {
      if (typeof item === 'string') {
        listHtml += `<li>${item}</li>`;
      }
      if (item.type === 'list') {
        listHtml += this.getList(item);
      }
    }
    listHtml += '</ul>';

    return listHtml;
  }

  getSelectedAnalysisVersionForSystem(index: number) {
    return this.selectedAnalysisVersion[index] as PackagingSystemRecyclabilityResultDto;
  }

  getSelectedAnalysisVersionForUnit(index: number) {
    return this.selectedAnalysisVersion[index] as PackagingUnitRecyclabilityResultDto;
  }

  validateAnalysis(valid: boolean, evaluatedCountry: RecyclabilityResult) {
    super.validateAnalysis(valid, evaluatedCountry);
  }

  openTab(countryName: string) {
    if (!countryName || countryName === '') { return; }
    const index = this.countryTabs._tabs.find(x => x.textLabel === countryName)?.position;
    this.countryTabs.selectedIndex = index ?? 0;
  }

  onHistoryVersionChange(version: (PackagingUnitRecyclabilityResultDto | PackagingSystemRecyclabilityResultDto)[]) {
    this.analysisId = version[0].id ?? '';
    this.setAuthorizations(version[0].evaluationReferenceTimestamp)
    this.setDataSourceForVersion(version);
  }

  getRecyclingStreamTooltip() {
    return this.translateService.instant('analysis.recyclabilityAnalysis.missingRecyclingStream');
  }

  private getSelectedAnalysisVersion() {
    return this.analysisVersions.find(x => x[0].id === this.analysisId) ?? this.analysisVersions[0];
  }

  private async setDataSourceForVersion(version: (PackagingUnitRecyclabilityResultDto | PackagingSystemRecyclabilityResultDto)[]) {
    this.dataSource = this.callerLevelId == PackagingPart.Unit ?
      version.map(countryResult => this.recService.getPackagingUnitRecyclabilityResultFromDto(
        countryResult as PackagingUnitRecyclabilityResultDto)) :
      version.map(countryResult => this.recService.getPackagingSystemRecyclabilityResultFromDto(
        countryResult as PackagingSystemRecyclabilityResultDto));
      this.dataSourceMap = await this.filternotAllowedCountries(this.dataSource,version[0].evaluationReferenceTimestamp);
  }

  isCountryAuthorized (countryCode : string) : boolean {
    return this.isCountryInAuthorizedList(countryCode, this.allowedCountries);
  }

  private async isCountryAuthorizedOnTimestamp (countryCode : string, timestamp : string) : Promise<boolean> {
    const allowedCountriesOnTime = await this.getAuthorizations(timestamp);
    return this.isCountryInAuthorizedList(countryCode, allowedCountriesOnTime);
  }

  private isCountryInAuthorizedList(countryCode: string, authorizations : CountryDto[]) {
    return authorizations.find((x) => x.code === countryCode) != null;
  }

  private async filternotAllowedCountries (datasource : (RecyclabilityResultPackagingUnit | RecyclabilityResultPackagingSystem)[],
  time: string): Promise<(RecyclabilityResultPackagingUnit | RecyclabilityResultPackagingSystem)[]> {
    const authorizations = await this.getAuthorizations(time)
    return datasource.filter(country => {return this.isCountryInAuthorizedList(country.evaluatedCountryCode,authorizations)})
  }

  private async setAuthorizations (time: string) {
    this.allowedCountries = await this.getAuthorizations(time);
  }
  private async getAuthorizations (time: string) {
    let tempCountries : CountryDto[] | undefined = this.authorizedCountries.get(time);
    if (tempCountries == undefined) {
      tempCountries = await this.licenseService.getAuthorizedCountriesFromTimeStampAsync(time);
      this.authorizedCountries.set(time,tempCountries);
    }
    return tempCountries;
  }
}
