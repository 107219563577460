<h1 mat-dialog-title>{{ 'report.generateReportTitle' | translate }}</h1>

<mat-dialog-content>
  <div *ngIf="callerLevel === packagingPartEnum.Unit || callerLevel === packagingPartEnum.System">
    <mat-form-field class="full-width">
      <mat-label>{{ 'report.reportCountries' | translate }}</mat-label>
      <mat-select [formControl]="selectedReportCountries" multiple>
        <mat-option *ngFor="let country of relevantCountries" [value]="country">{{country.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="selectedReportCountries.errors">
        <span *ngIf="selectedReportCountries.errors.required">
          {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
        </span>
      </mat-error>
    </mat-form-field>
  </div>

  <mat-form-field class="full-width">
    <mat-label>{{ 'report.reportName' | translate }}</mat-label>
    <input matInput [formControl]="reportName">
  </mat-form-field>

  <div *ngIf="reportTemplates.length > 0">
    <p>{{ 'report.reportTemplate' | translate }}</p>
    <div id="template-selection">
      <app-report-templates-overview [reportTemplates]="reportTemplates" [displayedColumns]="displayedColumns"
        [canSelectRows]="true" (selectedTemplateChange)="setSelectedReportTemplate($event)">
      </app-report-templates-overview>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="generateReport()" type="submit">
    {{ 'common.buttons.ok' | translate }}
  </button>
  <button mat-raised-button (click)="cancel()" type="button">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</mat-dialog-actions>
