<mat-card id="outer-card">
  <mat-card-header id="outer-card-header">
    <mat-card-title>
      <h2>{{ 'report.templateWizard' | translate }}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content id="outer-card-content">
    <div id="settings-container">
      <div id="inclusion-map-container">
        <div *ngFor="let subitem of includeSpec.subitems">
          <div *ngIf="selectedPackagingPart===packagingPartEnum.System||selectedPackagingPart===packagingPartEnum.Unit; else componentReport">
            <ng-container *ngTemplateOutlet="inclusionTemplate; context:{ item: subitem }"></ng-container>
          </div>
          <ng-template #componentReport>
            <div *ngFor="let subSubitem of subitem.subitems">
              <ng-container *ngTemplateOutlet="inclusionTemplate; context:{ item: subSubitem }">
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
      <form [formGroup]="form" id="form">
        <mat-form-field id="report-name-field">
          <mat-label class="label-large">{{ 'report.templateName' | translate }}</mat-label>
          <input matInput id="report-name-input" autocomplete="off" formControlName="reportName" required>
          <mat-error *ngIf="form.controls.reportName.errors">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </mat-error>
        </mat-form-field>
      </form>
      <div id="save-button-container">
        <button id="save-report-button" mat-raised-button color="primary" (click)="saveReport()">
          <mat-icon>save_alt</mat-icon>
          {{ 'report.buttons.saveTemplate' | translate }}
        </button>
      </div>
    </div>

    <div id="report-contents">
      <h2>{{ includeSpec.label }}</h2>
      <div *ngFor="let reportPart of includeSpec.subitems" class="report-part-card">
        <div *ngIf="reportPart.included">
          <mat-card class="padding-bottom">
            <mat-card-header>
              <h4>{{ reportPart.label }}</h4>
            </mat-card-header>
            <div *ngFor="let reportItem of reportPart.subitems">
              <div *ngIf="reportItem.included">
                <span *ngIf="reportItem.contentType===contentTypes.Image">
                  <div class="margin-container">
                    <div id="image-container">
                      <img [src]="imagePlaceholderSource" id="image-placeholder">
                    </div>
                  </div>
                </span>
                <span *ngIf="reportItem.contentType===contentTypes.DiagramPie">
                  <div class="margin-container">
                    <img [src]="pieChartSource" id="diagram-image">
                  </div>
                </span>
                <span *ngIf="reportItem.name==='ratingTable'">
                  <div class="margin-container">
                    <ng-container class="full-width"
                      *ngTemplateOutlet="tableTemplate; context:{ columns: columnsRatingTable }">
                    </ng-container>
                  </div>
                </span>
                <span *ngIf="reportItem.name==='percentageTable'">
                  <div class="margin-container">
                    <ng-container class="full-width"
                      *ngTemplateOutlet="tableTemplate; context:{ columns: columnsPercentageTable }">
                    </ng-container>
                  </div>
                </span>
                <div *ngIf="reportItem.contentType===contentTypes.List" class="margin-container">
                  <mat-expansion-panel expanded="false">
                    <mat-expansion-panel-header class="contents-pannel-header">
                      <h5>{{ reportItem.label }}</h5>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel-body class="fields-list-container">
                      <mat-selection-list #specList class="checkbox-list"
                        (selectionChange)="fieldsSelectionChanged(reportItem, specList.selectedOptions.selected)">
                        <mat-list-option [value]="field.name" [selected]="field.included"
                          *ngFor="let field of reportItem.associatedObject" checkboxPosition="before">
                          {{ field.label }}
                        </mat-list-option>
                      </mat-selection-list>
                    </mat-expansion-panel-body>
                  </mat-expansion-panel>
                </div>
                <div *ngIf="reportItem.name==='multiMaterialTable'" class="margin-container">
                  <app-material-layers-report-template></app-material-layers-report-template>
                </div>
                <div *ngIf="reportItem.name==='componentsSummary'">
                  <div class="margin-container">
                    <img [src]="componentsImageSource" id="components-image">
                    <ng-container *ngTemplateOutlet="tableTemplate; context:{ columns: columnsComponentsTable }">
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <mat-card *ngIf="displayAnnex" class="report-part-card">
        <mat-card-header>
          <h4>{{ 'report.annex' | translate }}</h4>
        </mat-card-header>
        <mat-card-content>
          <div id="image-container">
            <img [src]="documentPlaceholderSource" id="image-placeholder">
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #inclusionTemplate let-item="item">
  <mat-checkbox [checked]="item.included" [(ngModel)]="item.included" (change)="includeSubitems(item, $event.checked)">
    {{item.label}}
  </mat-checkbox>
  <div *ngIf="item.subitems && item.subitems.length > 0">
    <ul>
      <li *ngFor="let subitem of item.subitems">
        <ng-container *ngTemplateOutlet="inclusionTemplate; context:{ item: subitem }">
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #tableTemplate let-columns="columns">
  <mat-table class="table-template">
    <div *ngFor="let column of columns">
      <ng-container [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
          {{ 'report.tableColumn.' + column | translate }}
        </mat-header-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: columns;"></mat-row>
  </mat-table>
</ng-template>
