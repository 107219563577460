import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';
import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';

export enum AnalysisType {
  MasterData,
  Recyclability,
  Lifecycle,
  PlasticTax,
  LicenseFee,
  CradleToGateLifecycle
}

@Injectable({ providedIn: 'root' })
export class ParentNavigationService implements OnDestroy {

  protected dialogSubscription?: Subscription;
  protected evaluationSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog,
    protected translateService: TranslateService
  ) { }

  stopWhenAnalysisNotPermitted(): void {
    const data: SimpleDialogData = {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('warnings.analysisNotPermitted')], icon: 'info'
    };
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data));
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
    this.evaluationSubscription?.unsubscribe();
  }
}
