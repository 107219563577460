import { RecyclabilityResultPackagingUnit } from './../../../model/evaluations/recyclability-result-packaging-unit';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HistoryWrapperLifeCycle } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-life-cycle';
import { MatDialog } from '@angular/material/dialog';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { LifeCycleResultPackagingUnit } from 'src/app/model/evaluations/life-cycle-result-packaging-unit';
import { ComparePackagingPartComponent } from '../../shared-components/parent-components/compare-packaging-part/compare-packaging-part.component';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { ComparisonLcaRowDefinition, ComparisonRowDefinition } from 'src/app/util/analyses-util/recyclability-table-definitions';
import { DummyPackagingUnitLifeCycleResultDto, PackagingUnitLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { LifeCycleService } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { HistoryWrapperRecyclability } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-recyclability';
import { PackagingUnitRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { RecyclabilityService } from 'src/app/util/analyses-util/recyclability/recyclability-service';

@Component({
  selector: 'app-compare-packaging-units',
  templateUrl: './compare-packaging-units.component.html',
  styleUrls: ['./compare-packaging-units.component.scss']
})
export class ComparePackagingUnitsComponent extends ComparePackagingPartComponent implements OnInit {

  recDataSource: RecyclabilityResultPackagingUnit[][] = [];
  lcaDataSource: LifeCycleResultPackagingUnit[][] = [];

  constructor(
    protected analysisApiService: AnalysisApiService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private recyclabilityService: RecyclabilityService,
    private lifeCycleService: LifeCycleService
  ) {
    super(analysisApiService, translateService, dialog, spinner);
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.data.subscribe(async data => {
      const packagingUnits = data.packagingUnits;
      const countryCodes = super.getAllEvaluatedCountryCodes(packagingUnits);
      const lcaForComparison: HistoryWrapperLifeCycle[] = data.lcaForComparison;
      const recForComparison: HistoryWrapperRecyclability[] = data.recyclabilityForComparison;
      if (lcaForComparison) {
        this.lcaDataSource = this.getLcaResultsPackagingUnit(lcaForComparison, packagingUnits, countryCodes);
        this.volumeAvailable = this.allPackagingUnitsHaveVolume(packagingUnits);
        this.weightAvailable = this.allPackagingUnitsHaveWeight(packagingUnits);
        lcaForComparison.forEach(lcaWrapper => { this.newLcaAllowed.push(lcaWrapper.lifeCycleDtos.length === 0) });
      }
      if (recForComparison) {
        this.recDataSource = this.getRecResultsPackagingUnit(recForComparison, packagingUnits, countryCodes);
      }
      await super.setAnalysisPossibleData(this.recDataSource, this.lcaDataSource, PackagingPart.Unit);
      this.analysesDataLoaded = Promise.resolve(true);
    });
    this.recTableData = this.getRecTableData(this.recDataSource);
    this.lcaTableData = this.getLcaTableData(this.lcaDataSource);
  }

  // --- RECYCLABILITY ANALYSIS ---

  private getRecResultsPackagingUnit(
    recyclabilityWrappersList: HistoryWrapperRecyclability[], packagingUnits: PackagingUnitDto[], countryCodes: string[]
  ): RecyclabilityResultPackagingUnit[][] {
    const allRecResults: RecyclabilityResultPackagingUnit[][] = recyclabilityWrappersList.map(wrapper => {
      const recResults: RecyclabilityResultPackagingUnit[] = [];
      const packagingUnit = packagingUnits.find(x => x.id === wrapper.id);
      if (!packagingUnit) { return recResults; }
      const brandName = packagingUnit.brandName;
      const productName = packagingUnit.productName;
      const recDtos = wrapper.recyclabilityDtos[0] as PackagingUnitRecyclabilityResultDto[];
      countryCodes.forEach(countryCode => {
        const recDto = (recDtos && recDtos.find(x => x.evaluatedCountry === countryCode)) ??
          this.getDummyPackagingUnitRecyclability(wrapper.analysisId, wrapper.id, wrapper.version, brandName, productName, countryCode);
        const recResult = this.recyclabilityService.getPackagingUnitRecyclabilityResultFromDto(recDto);
        recResults.push(recResult);
      });
      return recResults;
    });
    const transposedRecResult = this.getRecDataSourcePackagingUnits(allRecResults, countryCodes);
    return transposedRecResult;
  }

  /**
   * Transpose evaluation matrix so that countries are in the foreground.
   * Length of data source corresponds with the amount of evaluated countries
   * throughout all analyses. If analysis for a country is not available,
   * a placeholder is used instead
   */
  private getRecDataSourcePackagingUnits(
    allEvaluations: RecyclabilityResultPackagingUnit[][], countryCodes: string[]
  ): RecyclabilityResultPackagingUnit[][] {
    const evaluationByCountry: RecyclabilityResultPackagingUnit[][] = [];
    countryCodes.forEach(_ => evaluationByCountry.push([]));
    for (const evaluation of allEvaluations) {
      for (let index = 0; index < countryCodes.length; index++) {
        const countryCode = countryCodes[index];
        const countryEval = evaluation.find(x => x.evaluatedCountryCode === countryCode);
        if (!countryEval) { throw new Error("ComparePackagingUnitsComponent: evaluation for country not found"); }
        evaluationByCountry[index].push(countryEval);
      }
    }
    return evaluationByCountry;
  }

  /**
   * A dummy analysis with unknown recyclability. To be used as placeholder
   * when analysis is not available for a packaging version
   */
  private getDummyPackagingUnitRecyclability(
    analysisId: string, packagingUnitId: number, version: number, brandName: string,
    productName: string, countryCode: string
  ): PackagingUnitRecyclabilityResultDto {
    const dummyPackagingUnit = new PackagingUnitDto();
    dummyPackagingUnit.id = packagingUnitId;
    dummyPackagingUnit.version = version;
    dummyPackagingUnit.brandName = brandName;
    dummyPackagingUnit.productName = productName;
    const returnDto = new PackagingUnitRecyclabilityResultDto();
    returnDto.id = analysisId;
    returnDto.analyzedPackagingUnit = dummyPackagingUnit;
    returnDto.evaluatedCountry = countryCode;
    returnDto.componentResults = [];
    super.setDefaultRecyclabilityData(returnDto);
    return returnDto;
  }

  private getRecTableData(recDataSource: RecyclabilityResultPackagingUnit[][]): ComparisonRowDefinition[][] {
    const recTableData: ComparisonRowDefinition[][] = [];
    if (!recDataSource) { return recTableData; }
    const allLabels = recDataSource[0][0].dataSource.map(x => x.label);
    for (const recCountryData of recDataSource) {
      const recCountryTableData: ComparisonRowDefinition[] = [];
      for (const packaging of recCountryData) {
        for (const label of allLabels) {
          super.getRecTableCellData(packaging.dataSource, label, recCountryTableData);
        }
        super.getRecTableCellData(packaging.dataSource, 'total', recCountryTableData, true);
      }
      recTableData.push(recCountryTableData);
    }
    return recTableData;
  }

  createNewRecyclabilityAnalysis(e: { id: number, version: number }) {
    super.createRecyclabilityAnalysis(e.id, e.version, PackagingPart.Unit);
  }

  // --- Life Cycle Assessment ---

  getLcaResultsPackagingUnit(
    lcaWrappersList: HistoryWrapperLifeCycle[], packagingUnits: PackagingUnitDto[], countryCodes: string[]
  ): LifeCycleResultPackagingUnit[][] {
    const allLcaResults: LifeCycleResultPackagingUnit[][] = lcaWrappersList.map(wrapper => {
      const lcaResults: LifeCycleResultPackagingUnit[] = [];
      const packagingUnit = packagingUnits?.find(y => y.id === wrapper.id);
      if (!packagingUnit) { return lcaResults; }
      const brandName = packagingUnit.brandName;
      const productName = packagingUnit.productName;
      // Grab the latest version of the analysis. All countries
      const lcaDtos = wrapper.lifeCycleDtos[0] as PackagingUnitLifeCycleResultDto[];
      const analysisExists = wrapper.lifeCycleDtos.length > 0;
      countryCodes.forEach(countryCode => {
        const lcaDto = (lcaDtos && lcaDtos.find(x => x.evaluatedCountry === countryCode)) ??
          this.getDummyPackagingUnitLca(wrapper.id, wrapper.version, brandName, productName, countryCode, analysisExists);
        const lcaResult = this.lifeCycleService.getPackagingUnitLcaResultFromDto(lcaDto);
        lcaResults.push(lcaResult)
      });
      return lcaResults;
    });
    return this.getLcaDataSourcePackagingUnits(allLcaResults, countryCodes);
  }

  private getLcaDataSourcePackagingUnits(
    allEvaluations: LifeCycleResultPackagingUnit[][], countryCodes: string[]
  ): LifeCycleResultPackagingUnit[][] {
    const evaluationByCountry: LifeCycleResultPackagingUnit[][] = [];
    countryCodes.forEach(_ => evaluationByCountry.push([]));
    for (const evaluation of allEvaluations) {
      for (let index = 0; index < countryCodes.length; index++) {
        const countryCode = countryCodes[index];
        const countryEval = evaluation ? evaluation.find(x => x.evaluatedCountryCode === countryCode) : null;
        if (!countryEval) { throw new Error("ComparePackagingUnitsComponent: evaluation for country not found"); }
        evaluationByCountry[index].push(countryEval);
      }
    }
    return evaluationByCountry;
  }

  private getDummyPackagingUnitLca(
    packagingUnitId: number, version: number, brandName: string,
    productName: string, countryCode: string, analysisExists: boolean
  ): DummyPackagingUnitLifeCycleResultDto {
    const dummyPackagingUnit = new PackagingUnitDto();
    dummyPackagingUnit.id = packagingUnitId;
    dummyPackagingUnit.version = version;
    dummyPackagingUnit.brandName = brandName;
    dummyPackagingUnit.productName = productName;
    dummyPackagingUnit.packagingVolume = 1000;
    dummyPackagingUnit.packagingQuantity = 1000;
    const emptyDto = new DummyPackagingUnitLifeCycleResultDto();
    emptyDto.analyzedPackagingUnit = dummyPackagingUnit;
    emptyDto.evaluatedCountry = countryCode;
    emptyDto.componentResults = [];
    emptyDto.analysisExists = analysisExists;
    return emptyDto;
  }

  private getLcaTableData(lcaDataSource: LifeCycleResultPackagingUnit[][]): ComparisonLcaRowDefinition[][] {
    const lcaTableData: ComparisonLcaRowDefinition[][] = [];
    if (!lcaDataSource) { return lcaTableData; }
    for (const lcaCountryData of lcaDataSource) {
      const lcaCountryTableData: ComparisonLcaRowDefinition[] = [];
      const allRelevantCategories: string[] = super.getRelevantEffectKeys(lcaCountryData);
      const allCategories: string[] = lcaCountryData[0].effectRelevances.map(x => x.key);
      for (const packaging of lcaCountryData) {
        for (const category of allCategories) {
          const effectsTotal = packaging?.lifeCycleDataSource?.environmentalEffectsTotal;
          const effectRelevances = packaging.effectRelevances
          const volume = packaging.packagingVolume ?? -1;
          const weight = packaging.packagingWeight ?? -1;
          super.getLcaTableCellData(effectsTotal, category, effectRelevances, lcaCountryTableData, volume, weight);
        }
      }
      lcaCountryTableData.forEach(x => x.isRelevant = allRelevantCategories.includes(x.key));
      const relevantTableData = lcaCountryTableData.filter(x => x.isRelevant === true);
      const remainingTableData = lcaCountryTableData.filter(x => x.isRelevant === false);
      lcaTableData.push(relevantTableData.concat(remainingTableData));
    }
    return lcaTableData;
  }

  createNewLifecycleAnalysis(e: { id: number, version: number }) {
    super.createLifecycleAnalysis(e.id, e.version, PackagingPart.Unit);
  }

  private allPackagingUnitsHaveVolume(packagingUnits: PackagingUnitDto[]): boolean {
    return packagingUnits.find(x => x.packagingVolume == null) === undefined;
  }

  private allPackagingUnitsHaveWeight(packagingUnits: PackagingUnitDto[]): boolean {
    return packagingUnits.find(x => x.packagingQuantity == null) === undefined;
  }
}
