import { ParentNavigationService } from './parent-navigation-service';
import { TranslateService } from '@ngx-translate/core';
import { PathResolveService } from '../../../services/path-resolve.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { COMPONENT_CATEGORIES } from '../component-type-service';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class ComponentNavigationService extends ParentNavigationService {

  private decorationInUrl: string;

  constructor(
    private router: Router,
    protected dialog: MatDialog,
    protected translateService: TranslateService
  ) {
    super(dialog, translateService);
    this.decorationInUrl = COMPONENT_CATEGORIES.find(x => x.key === PackagingComponentTypesEnum.Decoration)?.label ?? '';
  }

  navigateToComponent(componentTypeName: string, componentId: number) {
    const path = PathResolveService.getUpdateComponentPath(true, componentTypeName, componentId);
    this.router.navigate([path]);
  }

  navigateToDecoration(decorationTypeName: string, componentId: number) {
    const path = PathResolveService.getDecorationUpdatePath(true, this.decorationInUrl, componentId, decorationTypeName);
    this.router.navigate([path]);
  }

  navigateToHistoryComponent(componentTypeName: string, componentId: number, componentVersionId: number) {
    this.router.navigate([PathResolveService.getComponentHistoryPath(true, componentTypeName, componentId, componentVersionId)]);
  }

  navigateToHistoryDecoration(decorationTypeName: string, componentId: number, componentVersionId: number) {
    this.router.navigate([PathResolveService.getDecorationHistoryPath(true, this.decorationInUrl, componentId, componentVersionId,
      decorationTypeName)]);
  }

  navigateToComponentPreview(componentTypeName: string, candidateId: number, isChange = false, seeComponentBeforeChange = false) {
    const path = PathResolveService.getComponentPreviewPath(true, componentTypeName, candidateId, isChange, seeComponentBeforeChange);
    this.router.navigate([path]);
  }

  navigateToDecorationPreview(decorationTypeName: string, candidateId: number, isChange = false, seeComponentBeforeChange = false) {
    const path = PathResolveService.getDecorationPreviewPath(
      true, this.decorationInUrl, candidateId, isChange, seeComponentBeforeChange, decorationTypeName);
    this.router.navigate([path]);
  }
}
