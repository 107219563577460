import { ExportProfileImages, ExportProfileDocuments, setValues } from "../packaging-unit-entities/packaging-unit-export-profile-dto";

export class PackagingSystemExportProfileDto {
  id = -1;
  defaultPackagingUnitExportProfileId?: number;
  profileName = '';
  owningOrganizationId = -1;
  owningOrganizationName = '';
  isDefaultProfile = false;
  comment = false;
  brandName = false;
  productName = false;
  articleNumber = false;
  gtin = false;
  customFields = false;
  assemblyCountry = false;
  distributionCountries = false;
  length = false;
  width = false;
  height = false;
  images!: ExportProfileImages;
  documents!: ExportProfileDocuments;

  constructor(defaultBooleanValue?: boolean) {
    if (defaultBooleanValue == null) { return; }
    this.images = new ExportProfileImages(defaultBooleanValue);
    this.documents = new ExportProfileDocuments(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
    this.isDefaultProfile = false;
  }
}
